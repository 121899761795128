import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Trainmap from './components/Trainmap';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import { backend } from './config';

import 'ol/ol.css';
import './style.css';

const Countries = lazy(() => import('./components/Countries'));
const Country = lazy(() => import('./components/Country'));
const Tickets = lazy(() => import('./components/Tickets'));
const Search = lazy(() => import('./components/Search'));
const Stop = lazy(() => import('./components/Stop'));
const Destinations = lazy(() => import('./components/Destinations'));
const Destination = lazy(() => import('./components/Destination'));
const Trainroute = lazy(() => import('./components/Trainroute'));

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
};

const App = () => {
  const path = usePathname();
  const hrefLocation = window.location.href.replace('https://www.moontrains.ch', '');
  axios.get(`${backend}/dummy?href=${hrefLocation}&ref=${document.referrer}`);
  return (
    <>
      <div id="navcontainer">
        <Navigation />
      </div>
      <Trainmap path={path} />
      <div id="contentbody">
        <div id="content">
          <Suspense fallback={'Loading...'}>
            <Routes>
              <Route path="/destination/:label/:destinationId" element={<Destination />} />
              <Route path="/stop/:label/:stopId" element={<Stop />} />
              <Route path="/country/:country/:countrycode" element={<Country />} />
              <Route path="/route/:routeIds/:routeId/:date" element={<Trainroute />} />
              <Route path="/route/:routeIds/:routeId" element={<Trainroute />} />
              <Route path="/search" element={<Search />} />
              <Route path="/search/:fromWord/:fromId/:toWord/:toId" element={<Search />} />
              <Route path="/tickets" element={<Tickets />} />
              <Route path="/countries" element={<Countries />} />
              <Route path="/destinations" element={<Destinations />} />
              <Route path="/" element={<Home />} />
            </Routes>
          </Suspense>
        </div>
        <Footer />
      </div>
    </>
  );
};

const container = document.getElementById('all');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
