import { useState } from 'react';
import { Link } from 'react-router-dom';
import OutsideAlerter from './OutsideAlerter';

const Navigation = () => {
  const [navopen, setNavopen] = useState(false);

  let menu;
  if (navopen === true) {
    menu = (
      <div id="navigation">
        <Link to="/search" onClick={() => setNavopen(false)}>
          <div className="navelement">
            <span>Routensuche</span>
          </div>
        </Link>
        <Link to="/destinations" onClick={() => setNavopen(false)}>
          <div className="navelement">
            <span>Reiseziele</span>
          </div>
        </Link>
        <Link to="/tickets" onClick={() => setNavopen(false)}>
          <div className="navelement">
            <span>Tickets</span>
          </div>
        </Link>
        <Link to="/countries" onClick={() => setNavopen(false)}>
          <div className="navelement">
            <span>Länder</span>
          </div>
        </Link>

        <Link to="/" onClick={() => setNavopen(false)}>
          <div className="navelement">
            <span>Home</span>
          </div>
        </Link>
      </div>
    );
  } else {
    menu = <></>;
  }
  return (
    <OutsideAlerter onOutsideClick={() => setNavopen(false)}>
      <div>
        {menu}
        <div className="hamburger" onClick={() => setNavopen(!navopen)}>
          <div className="hamburgerline"></div>
          <div className="hamburgerline"></div>
          <div className="hamburgerline"></div>
        </div>
      </div>
    </OutsideAlerter>
  );
};

export default Navigation;
