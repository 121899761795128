import { useState } from 'react';
import axios from 'axios';
import { backend } from '../config';

const SearchBoxInputField = props => {
  let cancelToken = axios.CancelToken.source();
  const [cursorPosition, setCursorPosition] = useState();
  const [searchValues, setSearchValues] = useState([]);

  const typing = event => {
    cancelToken.cancel('Operation canceled due to input changes');
    cancelToken = axios.CancelToken.source();
    props.setSearchWord(event.target.value);
    props.setSearchId(false);
    axios
      .get(`${backend}/searchplaces?q=${event.target.value}`, { cancelToken: cancelToken.token })
      .then(response => {
        setSearchValues(response.data.query);
        props.setSearchId(response.data.query[0].id);
        setCursorPosition(-1);
      })
      .catch(err => {});
  };

  const handleOnKeyDown = event => {
    if (event.keyCode === 40) {
      //down arrow
      setCursorPosition(Math.min(cursorPosition + 1, searchValues.length - 1));
    } else if (event.keyCode === 38) {
      //up arrow
      setCursorPosition(Math.max(cursorPosition - 1, -1));
    } else if (event.keyCode === 13 && cursorPosition > -1) {
      //enter (one must have select an item from the dropdown)
      selectName(searchValues[cursorPosition]);
    }
  };

  const selectName = item => {
    setSearchValues([]);
    props.setSearchWord(item.label);
    props.setSearchId(item.id);
    setCursorPosition(-1);
  };

  const formatValue = item => {
    if (item.description) {
      return (
        <>
          {item.label} <span className="small">{item.description}</span>
        </>
      );
    }
    return item.label;
  };

  return (
    <div>
      <input
        type="text"
        placeholder={props.placeholder}
        onChange={e => typing(e)}
        onKeyDown={e => handleOnKeyDown(e)}
        onBlur={() => setSearchValues([])}
        value={props.searchWord}
      />
      <div className="searchresult">
        {searchValues.map((item, idx) => (
          <div
            onMouseDown={() => selectName(item)}
            key={item.id}
            className={cursorPosition === idx ? 'active' : null}
          >
            {formatValue(item)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchBoxInputField;
