import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { backend } from '../config';
import SearchBoxInputField from './SearchBoxInputField';

const SearchBox = () => {
  const [searchIdFrom, setSearchIdFrom] = useState(undefined);
  const [searchIdTo, setSearchIdTo] = useState(undefined);
  const [searchWordFrom, setSearchWordFrom] = useState('');
  const [searchWordTo, setSearchWordTo] = useState('');
  const [route, setRoute] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [startLabel, setStartLabel] = useState('');
  const [endLabel, setEndLabel] = useState('');

  const { fromWord, fromId, toWord, toId } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    if (fromWord && fromId && toWord && toId) {
      setSearchWordFrom(fromWord);
      setSearchWordTo(toWord);
      setSearchIdFrom(fromId);
      setSearchIdTo(toId);
      setStartLabel(fromWord);
      setEndLabel(toWord);
      axios
        .get(`${backend}/routing?from=${fromId}&to=${toId}`)
        .then(response => {
          setRoute(response.data.query);
        })
        .catch(err => {
          console.error('error', err);
        })
        .finally(() => {
          setShowResult(true);
        });
    }
  }, [fromWord, fromId, toWord, toId]);

  let searchresult = <></>;
  if (showResult && route.length === 0) {
    searchresult = (
      <section className="columns">
        <div className="columns_side"></div>
        <div className="columns_center routeresult">
          Keine Route von {startLabel} nach {endLabel} gefunden.
        </div>
        <div className="columns_side"></div>
      </section>
    );
  } else if (showResult) {
    let all_parts = [];
    let cnt = 1;
    let newStartLabel = startLabel;
    let newEndLabel = endLabel;
    let infos = [];
    if (startLabel !== route[0].start_stop_label) {
      newStartLabel = route[0].start_stop_label;
      infos.push(
        <div className="small">
          Kein Nachtzug mit Halt in {startLabel} gefunden. Wir verwendent stattdessen{' '}
          {newStartLabel} als Abgangsort.
        </div>
      );
    }
    if (endLabel !== route[route.length - 1].end_stop_label) {
      newEndLabel = route[route.length - 1].end_stop_label;
      infos.push(
        <div className="small">
          Kein Nachtzug mit Halt in {endLabel} gefunden. Wir verwendent stattdessen {newEndLabel}{' '}
          als Zielort.
        </div>
      );
    }
    for (let part of route) {
      if (part.route_id === 0) {
        continue;
      }
      all_parts.push(
        <div key={part.start_stop}>
          {part.route_id === -2 ? (
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/boat.png`}
              alt="Bahn"
              width="30"
              height="30"
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/train.png`}
              alt="Bahn"
              width="30"
              height="30"
            />
          )}
          <span>{cnt}. </span>
          <Link to={`/stop/${part.start_stop_label}/${part.start_stop}`} className="cityname">
            {part.start_stop_label}
          </Link>
          <span>-</span>
          <Link to={`/stop/${part.end_stop_label}/${part.end_stop}`} className="cityname">
            {part.end_stop_label}
          </Link>{' '}
          <span className="small">
            (
            {part.route_id > 0 ? (
              <>
                Nachtzug{' '}
                <Link to={`/route/${part.route_id}/${part.route_id}`} className="cityname">
                  {part.long_name}
                </Link>
              </>
            ) : part.route_id === -1 ? (
              <>Verbindung am Tag</>
            ) : (
              <>Schiff</>
            )}
            )
          </span>
        </div>
      );
      cnt += 1;
    }
    searchresult = (
      <section className="columns">
        <div className="columns_side"></div>
        <div className="columns_center routeresult">
          {infos}
          Deine Route von {newStartLabel} nach {newEndLabel}:
          <br />
          {all_parts}
        </div>
        <div className="columns_side"></div>
      </section>
    );
  }

  return (
    <>
      <div className="search">
        <SearchBoxInputField
          setSearchId={setSearchIdFrom}
          setSearchWord={setSearchWordFrom}
          searchWord={searchWordFrom}
          placeholder="von"
        />
        <SearchBoxInputField
          setSearchId={setSearchIdTo}
          setSearchWord={setSearchWordTo}
          searchWord={searchWordTo}
          placeholder="nach"
        />
        <button
          className="blankbutton"
          onClick={() =>
            navigate(`/search/${searchWordFrom}/${searchIdFrom}/${searchWordTo}/${searchIdTo}`)
          }
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/search.png`}
            alt="suche"
            width="30"
            height="30"
          />
        </button>
      </div>
      <div>{searchresult}</div>
    </>
  );
};

export default SearchBox;
