import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { backend } from '../config.js';

const DestinationBox = props => {
  const [image, setImage] = useState('');
  const [label, setLabel] = useState('');

  useEffect(() => {
    if (!props.label || !props.image) {
      axios
        .get(`${backend}/getdestinationbox?ids=${props.id}`)
        .then(response => {
          const data = response.data.query[0];
          if (data) {
            setImage(data.file_name);
            setLabel(data.label_de);
          }
        })
        .catch(error => console.error(error));
    } else {
      setImage(props.image);
      setLabel(props.label);
    }
  }, [props]);

  let bottom = 30;
  let classname = 'destinationboxbig';
  if (props.size && props.size === 'small') {
    classname = 'destinationboxsmall';
    bottom = 10;
  }

  if (!image || !label) {
    return <></>;
  }

  return (
    <Link to={`/destination/${label}/d${props.id}`} key={props.id}>
      <div className={`destinationbox ${classname}`}>
        <img
          src={`${process.env.PUBLIC_URL}/images/destbox/${image}`}
          width="100%"
          height="100%"
          alt={label}
        />
        <p style={{ bottom: bottom }}>{label}</p>
      </div>
    </Link>
  );
};

export default DestinationBox;
