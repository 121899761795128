import React from 'react';
import smooth from 'chaikin-smooth';

const makeSmooth = (path, numIterations) => {
  while (numIterations > 0) {
    path = smooth(path);
    numIterations--;
  }
  return path;
};

const formatDate = date => {
  if (!date) {
    return new Date().toISOString().slice(0, 10);
  }
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, 0);
  const day = date.getDate().toString().padStart(2, 0);
  const datestring = `${year}-${month}-${day}`;
  return datestring;
};

const formatTime = time => {
  if (!time || time === '') {
    return '';
  }
  let timeArray = time.split(':', 2);
  let hour = parseInt(timeArray[0]);
  if (hour > 23) {
    let newHour = (hour % 24).toString();
    let newDay = Math.floor(hour / 24).toString();
    if (newHour.length === 1) {
      newHour = '0' + newHour;
    }
    let newTime = (
      <span>
        {newHour}:{timeArray[1]} <sup>+{newDay}</sup>
      </span>
    );
    return newTime;
  }
  return (
    <span>
      {timeArray[0]}:{timeArray[1]}
    </span>
  );
};

const months = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];

const countryNames_de = {
  AR: 'Argentinien',
  AU: 'Australien',
  BY: 'Belarus',
  BE: 'Belgien',
  BG: 'Bulgarien',
  CN: 'China',
  DK: 'Dänemark',
  DE: 'Deutschland',
  EE: 'Estland',
  FI: 'Finland',
  FR: 'Frankreich',
  GR: 'Griechenland',
  CA: 'Kanada',
  HR: 'Kroatien',
  LV: 'Lettland',
  MD: 'Moldau',
  MC: 'Monaco',
  MN: 'Mongolei',
  ME: 'Montenergro',
  MK: 'Nordmazedonien',
  NL: 'Niederlande',
  NO: 'Norwegen',
  AT: 'Österreich',
  IT: 'Italien',
  IR: 'Iran',
  PL: 'Polen',
  PT: 'Portugal',
  RO: 'Rumänien',
  RU: 'Russland',
  SE: 'Schweden',
  CH: 'Schweiz',
  RS: 'Serbien',
  SK: 'Slowakei',
  SI: 'Slowenien',
  ES: 'Spanien',
  TH: 'Thailand',
  CZ: 'Tschechien',
  TR: 'Türkei',
  HU: 'Ungarn',
  UA: 'Ukraine',
  US: 'USA',
  UK: 'Vereinigtes Königreich',
};

const license_url = {
  'GFDL-1.2+': 'http://www.gnu.org/licenses/old-licenses/fdl-1.2.html',
  'CC BY 2.0': 'https://creativecommons.org/licenses/by/2.0/',
  'CC-BY-3.0': 'https://creativecommons.org/licenses/by/3.0/',
  'CC BY 4.0': 'https://creativecommons.org/licenses/by/4.0/',
  'CC-BY-SA-1.0': 'https://creativecommons.org/licenses/by-sa/1.0/',
  'CC-BY-SA-2.0': 'https://creativecommons.org/licenses/by-sa/2.0/',
  'CC BY-SA 2.5': 'https://creativecommons.org/licenses/by-sa/2.5/',
  'CC-BY-SA-2.5-CH': 'https://creativecommons.org/licenses/by-sa/2.5/ch/',
  'CC BY-SA 3.0': 'https://creativecommons.org/licenses/by-sa/3.0/',
  'CC BY-SA 4.0': 'https://creativecommons.org/licenses/by-sa/4.0/',
};

export { makeSmooth, formatTime, formatDate, months, countryNames_de, license_url };
